.WalletAndNetwork{
    text-align: center;
    background-color: aqua;
    height: 300px;
    width: 100%;
    border-radius: 5px;
}
.Button1{
    background-color: aliceblue;
   padding: 20px;
   font-size: 20px;
   font-style: italic;
   border-radius: 5px;
}
.Button1:hover{
    background-color: burlywood;
}
.ConnectButton{
    background-color: aliceblue;
    padding: 20px;
    font-size: 20px;
    font-style: oblique;
    border-radius: 5px;
    border-style: solid;
    border-width: 3px;
}
.ConnectButton:hover{
    background-color: rgb(154, 209, 215);
}
.WalletAddress {
    font-weight: bold;
    font-size: 20px;
   color: rgb(40, 32, 198);
}
.disConnectButton {
    background-color: aliceblue;
    padding: 20px;
    font-size: 20px;
    border-radius: 5px;
    border-style: solid;
    border-width: 3px;
}
.disConnectButton:hover{
    background-color: aquamarine;
}
.BalanceWallet span{
font-family: 'Times New Roman', Times, serif;
font-size: large;
font-weight: bolder;
}
.StakeAndUnstake{
    text-align: center;
    background-color: silver;
    height: 1400px;
    width: 100%;
    border-radius: 5px;
}
.ControlStakeAndUnstake{
    padding: 40px;
}
.ControlStakeAndUnstake form select:hover{
     background-color: aqua;
}
.ControlStakeAndUnstake form input{
    text-align: center;
    background-color: aliceblue;
    border-radius: 7px;
    font-size: 20px;
    padding: 20px;
    color: black;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
}
.ControlStakeAndUnstake form input:hover{
    background-color: aqua;
    
}
.ControlStakeAndUnstake form button{
    background-color: aqua;
    font-size: 20px;
    padding: 20px;
    margin: 20px;
    font-weight: bolder;
    border-radius: 7px;
}
.ControlStakeAndUnstake form b{
    font-size: 20px;
}
.ControlStakeAndUnstake form button:hover{
    background-color: aliceblue;
}
.handleApr form input{
    background-color: aqua;
    font-size: 20px;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 7px;
    padding-top: 20px;
    padding-bottom: 20px;
    color: black;
    margin: 5px;
    font-weight: bolder;
    font-family: 'Times New Roman', Times, serif;
}
.handleApr form input:hover{
    background-color: aliceblue;
}
.AprStyle  {
    font-size: 30px;
    padding-bottom: 20px;
    padding-top: 40px;

}
.HandlUnstake  {
}
.HandlUnstake input{
    text-align: center;
    border-style: solid;
    border-color: aqua;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bolder;
}
.Note{
    font-family: 'Times New Roman', Times, serif;
    font-weight: bolder;
    border-style: solid;
    border-width: 5px;
    border-color: aqua;
    text-align: center;
    height: 150px;
    width: 50%;
    border: 5px solid white;
    box-sizing: border-box; 
    margin-left: auto; /* Center horizontally */
    margin-right: auto; /* Center horizontally */
}
.ClaimAndCompund {
    padding: 20px;

}
.ClaimAndCompund button{
    background-color: aqua;
    padding: 20px;
    margin: 20px;
    border-radius: 5px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 20px;
    font-weight: bolder;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    
}
.ClaimAndCompund button:hover{
    background-color: aliceblue;
}
.handleAutoReward {
    padding: 20px;
}
.handleAutoReward button{
    padding: 20px;
    background-color: yellowgreen;
    color: black;
    font-size: 20px;
    border-radius: 5px;
    font-family:'Times New Roman', Times, serif;
    font-weight: bolder;
}
.handleAutoReward button:hover{
    background-color: aqua;
}